import React from 'react';


const Transparency = () => {
  return (
    <div>
      <h1>Transparencia</h1>
      <p>En esta página se encuentran los documentos relacionados con la  transparencia de la asociación</p>

      <li> Documentos<br /></li>

      <ul>
        <li> <a href="resources/documentos/díptico mayormente divertido 3.pdf" download="díptico mayormente divertido 3.pdf"> Díptico mayormente divertido 3 </a> </li>
        <li> <a href="resources/documentos/MEMORIA ACTIVIDAD 2021.pdf" download="MEMORIA ACTIVIDAD 2021.pdf"> Memoria actividad 2021 </a> </li>
        <li> <a href="/resources/documentos/CD.pdf" download="Código ético.pdf"> Código ético </a> </li>
        <li> <a href="/resources/documentos/vision.pdf" download="Visión, misión y valores de Afadla.pdf">Visión, misión y valores de Afadla</a></li>
        <li> <a href="/resources/documentos/BS.pdf" download="Balance de situación 2019.pdf">Balance de situación 2019</a></li>
        <li> <a href="/resources/documentos/Igualdad.pdf" download="Plan de igualdad Afadla 2019">Plan de igualdad AFADLA 2019 </a></li>
        <li> <a href="/resources/documentos/EM.pdf" download="Estatutos modificados 2020.pdf">Estatutos modificados 2020 </a></li>
        <li> <a href="/resources/documentos/Memoria.pdf" download="Memoria de actividades 2020.pdf">Memoria de actividades 2020 </a></li>

      </ul>
    </div>
  );
}

export default Transparency;